import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import FeatureFlags from 'common/feature_flags';
import { getCurrentUser } from 'common/current_user';
import Responsive from './lib/Responsive';

import AlertNotice from './components/AlertNotice';
import AssetActionBar from 'common/components/AssetActionBar';
import BlobDownload from './components/BlobDownload';
import BlobPreview from './components/BlobPreview';
import ContactFormNotice from './components/ContactForm/ContactFormNotice';
import DatasetArchives from './components/DatasetArchives';
import DatasetPreview from './components/DatasetPreviewContainer';
import FeaturedContent from './components/FeaturedContent';
import FederatedAssetNotice from './components/FederatedAssetNotice';
import HrefDownload from './components/HrefDownload';
import NewInfoPane from './components/NewInfoPane';
import MetadataTable from './components/MetadataTable';
import PublishNotice from './components/PublishNotice';
import RelatedViewList from './components/RelatedViewList';
import RowDetails from './components/RowDetails';
import SchemaPreview from './components/SchemaPreview';
import ScheduleFailureNotice from './components/ScheduleFailureNotice';
import { collectionParentLink } from './components/CollectionParentLink';
import TabViewSwitcher, { DslpTab } from './components/TabViewSwitcher';
import ExportModal, { TOGGLE_OPTIONS } from 'common/components/ExportModal';
import { getQueryStringFromVif, getQueryStringFromView } from 'common/components/ExportModal/ExportHelper';
import { none } from 'ts-option';
import { fetchAIFourfourWhitelist } from 'common/core/configurations';

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exportModalOpen: false,
      currentVif: null,
      exportModalDefaultToggle: TOGGLE_OPTIONS.DOWNLOAD_FILE,
      aiConfigsFourFours: []
    };
  }

  componentDidMount() {
    this.fetchAiConfigs(this.props.view);
  }

  fetchAiConfigs = async (view) => {
    const fourfours = await fetchAIFourfourWhitelist(view.coreView.domainCName).then((response) => {
      return _.get(response, '[0].properties[0].value', []);
    });
    this.setState({ aiConfigsFourFours: fourfours });
  };

  showCollectionParent = () =>
    FeatureFlags.value('usaid_features_enabled') && this.props.view && this.props.view.collectionParentView;

  renderAssetActionBar() {
    return <AssetActionBar view={this.props.view.coreView} user={getCurrentUser()} />;
  }

  openExportDialog = (exportModalDefaultToggle) => {
    this.setState({ exportModalDefaultToggle: exportModalDefaultToggle });
    this.setState({ exportModalOpen: true });
  };

  closeExportDialog = () => {
    this.setState({ exportModalOpen: false });
    this.setState({ exportModalDefaultToggle: TOGGLE_OPTIONS.DOWNLOAD_FILE });
  };

  onVifUpdate = (vif) => {
    this.setState({ currentVif: vif });
  };

  renderAiChatbotContainer() {
    if (
      FeatureFlags.value('enable_ai_chatbot') &&
      this.state.aiConfigsFourFours.includes(this.props.view.id)
    ) {
      return <div id="ai-chatbot-container"></div>;
    } else {
      return null;
    }
  }

  renderNoticesAndAAB() {
    return (
      <div>
        {this.renderAssetActionBar()}
        <FederatedAssetNotice />
        <AlertNotice />
        {FeatureFlags.value('disable_grid_view_access') || <PublishNotice />}
        <ScheduleFailureNotice />
        <ContactFormNotice />
        {this.state.exportModalOpen && this.renderExportModal()}
      </div>
    );
  }

  renderExportModal() {
    const { view } = this.props;
    const { currentVif } = this.state;
    let queryStringClause;

    if (currentVif) {
      // We only want to set the queryString if its different from the core view's queryString
      const coreViewSelectString = getQueryStringFromView(view.coreView);
      const vifQueryString = getQueryStringFromVif(currentVif);
      queryStringClause = vifQueryString !== coreViewSelectString ? vifQueryString : null;
    }

    return (
      <ExportModal
        defaultToggleOption={this.state.exportModalDefaultToggle}
        query={none} // this is a query AST object on explore_grid
        queryStringClause={queryStringClause}
        view={view.coreView}
        apiFoundryUrl={view.apiFoundryUrl}
        totalRowCount={view?.rowCount}
        bodyText={view.name}
        clientContextVariables={[]}
        fourfour={view.id}
        showDataToggles={true}
        onDismiss={this.closeExportDialog}
      />
    );
  }

  renderFeatureContent() {
    let rowIdentifierName;
    const { coreView } = this.props.view;
    if (coreView.assetType === 'dataset') {
      rowIdentifierName = coreView.columns.find(
        (column) => column.id === coreView.rowIdentifierColumnId
      )?.name;
    }

    return (
      <div>
        <FeaturedContent />
        {this.showCollectionParent() && collectionParentLink(this.props.view.collectionParentView)}
        <MetadataTable />
        <RowDetails rowIdentifier={rowIdentifierName} />
        <div data-testid="app-schema-preview">
          <SchemaPreview />
        </div>
      </div>
    );
  }

  renderResponsiveContent() {
    return (
      <div className="container landing-page-container">
        <Responsive>
          <RelatedViewList parentUpdatedAt={this.props.view.lastUpdatedAt} />
        </Responsive>
      </div>
    );
  }

  renderDatasetContainer() {
    return [
      <div key={0}>
        <div className="container landing-page-container">
          <NewInfoPane />
          {this.renderAiChatbotContainer()}
          {this.renderFeatureContent()}
          <Responsive>{FeatureFlags.value('enable_asset_archival') && <DatasetArchives />}</Responsive>
        </div>
      </div>,
      <div key={1}>
        <DatasetPreview onVifUpdate={this.onVifUpdate} />
      </div>,
      <div key={2}>{this.renderResponsiveContent()}</div>
    ];
  }

  renderBlobContainer() {
    return [
      <div key={0}>
        <div className="container landing-page-container">
          <NewInfoPane />
          {this.renderAiChatbotContainer()}
          <BlobPreview />
          <FeaturedContent />
          {this.showCollectionParent() && collectionParentLink(this.props.view.collectionParentView)}
          <BlobDownload />
          <MetadataTable />
        </div>
      </div>
    ];
  }

  renderHrefContainer() {
    return [
      <div key={0}>
        <div className="container landing-page-container">
          <NewInfoPane />
          {this.renderAiChatbotContainer()}
          <FeaturedContent />
          {this.showCollectionParent() && collectionParentLink(this.props.view.collectionParentView)}
          <HrefDownload />
          <MetadataTable />
        </div>
      </div>
    ];
  }

  render() {
    const { view } = this.props;
    let child = null;
    if (view.isBlobby) {
      child = this.renderBlobContainer();
    } else if (view.isHref) {
      child = this.renderHrefContainer();
    } else {
      child = this.renderDatasetContainer();
    }

    const tabFromUrl = () => {
      const path = this.props?.route?.path;
      if (path) {
        const lastSegment = path.substring(path.lastIndexOf('/') + 1);
        // Ensure its a valid tab
        if ([DslpTab.About, DslpTab.Data, DslpTab.RelatedContent].includes(lastSegment)) return lastSegment;
      }
      //default tab
      return DslpTab.About;
    };

    return (
      <div>
        {this.renderNoticesAndAAB()}
        <TabViewSwitcher view={view} openExportDialog={this.openExportDialog} currentTab={tabFromUrl()}>
          {child}
        </TabViewSwitcher>
      </div>
    );
  }
}

App.propTypes = {
  view: PropTypes.object,
  params: PropTypes.object
};

function mapStateToProps(state) {
  return _.pick(state, 'view');
}

export default connect(mapStateToProps)(App);
