import { connect } from 'react-redux';
import CommonSchemaPreview from 'common/components/SchemaColumnPreview';

// TODO: This allows the tests to stay in the same place; remove it once the tests move to common
export const SchemaPreview = CommonSchemaPreview;

function mapStateToProps(state) {
  return {
    columns: state.view.columns
  };
}

export default connect(mapStateToProps)(CommonSchemaPreview);
